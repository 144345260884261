
import Vue from "vue";
import Component from "vue-class-component";
import MarketEditor from "@/components/MarketEditor";
import { Routes } from "@/router/routes";
import { Market } from "@/models/markets/Market";
import { marketModule } from "@/store/modules/market";
import { MarketActions } from "@/store/modules/market/actions";
import { MarketGetters } from "@/store/modules/market/getters";

@Component({
    components: {
        MarketEditor
    },
    methods: {
        ...marketModule.mapActions({
            createMarket: MarketActions.CreateMarket
        })
    },
    computed: {
        ...marketModule.mapGetters({
            isLoading: MarketGetters.IsLoading,
            market: MarketGetters.Market,
            errors: MarketGetters.Errors
        })
    }
})
export default class CreateMarket extends Vue {
    private readonly createMarket!: (market: Market) => Promise<void>;

    protected readonly isLoading!: boolean;
    private readonly market!: Market | null;
    private readonly errors!: string[] | null;

    protected snackbar = false;

    protected readonly headerItems = [
        {
            text: "OVERVIEW",
            disabled: false,
            to: Routes.Markets
        },
        {
            text: "CREATE",
            disabled: true
        }
    ];

    get snackbarErrors(): string {
        if (this.errors?.length) {
            return this.errors.join(" ");
        }
        return "Unknown error!";
    }

    async create(market: Market): Promise<void> {
        await this.createMarket(market);

        if (this.market?.marketId && !this.errors?.length) {
            this.$router.push(Routes.Markets);
        } else {
            this.snackbar = true;
        }
    }
}
