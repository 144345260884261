
import Vue from "vue";
import Component from "vue-class-component";
import MarketEditor from "@/components/MarketEditor";
import { marketModule } from "@/store/modules/market";
import { MarketActions } from "@/store/modules/market/actions";
import { MarketGetters } from "@/store/modules/market/getters";
import { Routes } from "@/router/routes";
import { Market } from "@/models/markets/Market";

@Component({
    components: { MarketEditor },
    methods: {
        ...marketModule.mapActions({
            loadMarketById: MarketActions.LoadMarketById,
            updateMarket: MarketActions.UpdateMarket
        })
    },
    computed: {
        ...marketModule.mapGetters({
            market: MarketGetters.Market,
            isLoading: MarketGetters.IsLoading,
            errors: MarketGetters.Errors
        })
    }
})
export default class EditMarket extends Vue {
    private readonly loadMarketById!: (marketId: number) => Promise<void>;
    private readonly updateMarket!: (market: Market) => Promise<void>;

    protected readonly isLoading!: boolean;
    private readonly market!: Market | null;
    private readonly errors!: string[] | null;

    protected snackbar = false;
    protected currentMarket: Partial<Market> = {};

    protected readonly headerItems = [
        {
            text: "OVERVIEW",
            disabled: false,
            to: Routes.Markets
        },
        {
            text: "EDIT",
            disabled: true
        }
    ];

    get snackbarErrors(): string {
        if (this.errors?.length) {
            return this.errors.join(" ");
        }
        return "Unknown error!";
    }

    get marketId(): number | null {
        const marketId = Number(this.$route.params.id);
        return !isNaN(marketId) ? marketId : null;
    }

    created(): void {
        if (this.marketId === null) {
            this.$router.push(Routes.Markets);
        } else {
            this.loadMarketById(this.marketId).then(() => {
                if (this.market) {
                    this.currentMarket = {
                        ...this.market,
                        marketEmailSettings:
                            this.market.marketEmailSettings?.map((s) => ({
                                ...s
                            }))
                    };
                }
            });
        }
    }

    async update(market: Market): Promise<void> {
        await this.updateMarket(market);

        if (!this.errors?.length) {
            this.$router.push(Routes.Markets);
        } else {
            this.snackbar = true;
        }
    }
}
