
import { MarketEmailSettings } from "@/models/markets/MarketEmailSettings";
import validationRules from "@/services/validationRules";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class MarketEmailEditor extends Vue {
    @Prop({ type: Object, required: true })
    protected readonly settings!: MarketEmailSettings;

    protected emailRules = [validationRules.multipleEmails()];
}
