
import { Market } from "@/models/markets/Market";
import { Routes } from "@/router/routes";
import { marketModule } from "@/store/modules/market";
import { MarketActions } from "@/store/modules/market/actions";
import { MarketGetters } from "@/store/modules/market/getters";
import Vue from "vue";
import Component from "vue-class-component";
import ConfirmationDialog from "@/components/ConfirmationDialog";

@Component({
    components: { ConfirmationDialog },
    computed: {
        ...marketModule.mapGetters({
            markets: MarketGetters.Markets,
            isLoading: MarketGetters.IsLoading,
            regions: MarketGetters.Regions
        })
    },
    methods: {
        ...marketModule.mapActions({
            loadMarkets: MarketActions.LoadMarkets,
            loadRegions: MarketActions.LoadRegions,
            deleteMarket: MarketActions.DeleteMarket
        })
    }
})
export default class MarketList extends Vue {
    private readonly loadMarkets!: () => Promise<void>;
    private readonly loadRegions!: () => Promise<void>;
    private readonly deleteMarket!: (marketId: number) => Promise<void>;

    protected readonly isLoading!: boolean;
    protected readonly markets!: Market[];
    protected readonly regions!: string[];

    protected headers = [
        { text: "Country Code", value: "countryCode" },
        { text: "Name", value: "name" },
        { text: "Region", value: "region" },
        {
            text: "Actions",
            value: "actions",
            sortable: false,
            filterable: false
        }
    ];

    protected deleteDialog = false;
    protected search = "";
    protected region = "";

    private selectedItemId: number | null = null;

    get filteredMarkets(): Market[] {
        if (this.region && this.region !== "All") {
            return this.markets.filter((m) => m.region === this.region);
        }
        return this.markets;
    }

    get regionItems(): string[] {
        return ["All", ...this.regions];
    }

    created(): void {
        // Loading markets without pagination because the number of markets is limited and the maximum number is the number of countries
        this.loadMarkets();
        this.loadRegions();
    }

    addNewMarket(): void {
        this.$router.push(Routes.MarketCreate);
    }

    editItem(market: Market): void {
        if (market.marketId) {
            this.$router.push(Routes.MarketEdit + market.marketId);
        }
    }

    deleteItem(market: Market): void {
        this.selectedItemId = market.marketId;
        this.deleteDialog = true;
    }

    confirmDeleteMarket(): void {
        if (this.selectedItemId) {
            this.deleteMarket(this.selectedItemId);
        }
    }
}
