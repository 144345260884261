
import { Market } from "@/models/markets/Market";
import { MarketEmailSettings } from "@/models/markets/MarketEmailSettings";
import { MarketInfo } from "@/models/markets/MarketInfo";
import { marketModule } from "@/store/modules/market";
import { MarketActions } from "@/store/modules/market/actions";
import { MarketGetters } from "@/store/modules/market/getters";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import MarketInfoEditor from "@/components/MarketInfoEditor";
import MarketEmailEditor from "@/components/MarketEmailEditor";
import BaseButton from "@/components/BaseButton";
import { VuetifyForm } from "@/models/VuetifyForm";

@Component({
    components: {
        MarketInfoEditor,
        MarketEmailEditor,
        BaseButton
    },
    computed: {
        ...marketModule.mapGetters({
            info: MarketGetters.Info
        })
    },
    methods: {
        ...marketModule.mapActions({
            loadInfo: MarketActions.LoadInfo
        })
    }
})
export default class MarketEditor extends Vue {
    protected readonly info!: MarketInfo | null;

    private readonly loadInfo!: () => Promise<void>;

    @Prop({ type: Object, default: () => ({}) })
    public readonly market!: Partial<Market>;

    @Prop({ type: Boolean, default: false })
    public readonly isEdit!: boolean;

    @Prop({ type: Boolean, default: false })
    public readonly loading!: boolean;

    $refs!: {
        form: VuetifyForm;
    };

    get items(): string[] {
        const defaultItems = ["General"];
        if (!this.info) return defaultItems;

        return [...defaultItems, ...this.info.emailTypes.map((e) => e.name)];
    }

    created(): void {
        this.loadInfo();
    }

    private tab = 0;
    get selectedTab(): number {
        return this.tab;
    }
    set selectedTab(value: number) {
        const emailType = this.info?.emailTypes.find(
            (e) => e.name === this.items[value]
        );
        if (emailType) {
            if (!this.market.marketEmailSettings) {
                this.market.marketEmailSettings = [
                    {
                        marketId: this.market.marketId ?? 0,
                        marketEmailTypeId: emailType.marketEmailTypeId
                    }
                ];
            } else if (
                !this.market.marketEmailSettings.find(
                    (me) => me.marketEmailTypeId == emailType.marketEmailTypeId
                )
            ) {
                this.market.marketEmailSettings.push({
                    marketId: this.market.marketId ?? 0,
                    marketEmailTypeId: emailType.marketEmailTypeId
                });
            }
        }
        this.tab = value;
    }

    getMarketEmailSettings(emailTypeName: string): MarketEmailSettings | null {
        const emailType = this.info?.emailTypes.find(
            (e) => e.name === emailTypeName
        );
        if (emailType) {
            return (
                this.market.marketEmailSettings?.find(
                    (me) => me.marketEmailTypeId == emailType.marketEmailTypeId
                ) ?? null
            );
        }

        return null;
    }

    submit(): void {
        const result = this.$refs.form.validate();
        if (result) {
            this.$emit("submit", this.market);
        } else {
            this.tab = 0;
        }
    }
}
