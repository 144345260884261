
import { Market } from "@/models/markets/Market";
import { MarketInfo } from "@/models/markets/MarketInfo";
import { marketModule } from "@/store/modules/market";
import { MarketGetters } from "@/store/modules/market/getters";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import validationRules from "@/services/validationRules";

@Component({
    computed: {
        ...marketModule.mapGetters({
            info: MarketGetters.Info
        })
    }
})
export default class MarketInfoEditor extends Vue {
    protected readonly info!: MarketInfo | null;

    @Prop({ type: Object, required: true })
    public readonly market!: Market;

    @Prop({ type: Boolean, default: false })
    public readonly loading!: boolean;

    get countryCodes(): string[] {
        if (this.info) {
            if (
                this.market.countryCode &&
                !this.info.countryCodes.includes(this.market.countryCode)
            ) {
                return [...this.info.countryCodes, this.market.countryCode];
            }
            return this.info.countryCodes;
        }
        return [];
    }

    protected nameRules = [
        validationRules.required("Name"),
        validationRules.minLength(2, "Name"),
        validationRules.maxLength(50, "Name")
    ];
    protected regionRules = [
        validationRules.required("Region"),
        validationRules.minLength(2, "Region"),
        validationRules.maxLength(50, "Region")
    ];
    protected countryCodeRules = [validationRules.required("Country code")];
}
